import React from "react"
import styled from "styled-components"
//import ReactGA from "react-ga"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero from "../components/CustomerSegmentHero"
import CustomerSegmentValueProps from "../components/CustomerSegmentValueProps"
import CustomerSegmentCarousel from "../components/CustomerSegmentCarousel"
import CustomerSegmentCenterTestingBlurb from "../components/CustomerSegmentCenterTestingBlurb"
import CustomerSegmentQuestion1 from "../components/CustomerSegmentQuestion1"
import CustomerSegmentApp from "../components/CustomerSegmentApp"

import Case1 from "../images/case_steph.jpg"
import Case2 from "../images/case_droils.jpg"

import Screen1 from "../images/erp-laptop-screen1.jpeg";
import Screen2 from "../images/erp-laptop-screen2.jpeg";

import HeroBackground from "../images/erp-hero-bg.jpg";
import ErpDescription from "../images/erp-description.jpeg";

import PC1 from "../images/erp-slider1.jpeg"
import PC2 from "../images/erp-slider2.jpeg"
import PC3 from "../images/erp-slider3.jpeg"


import { sizes } from "../lib/layout"
import { ERP_FEATURES } from "../lib/constants";

const Makers = props => {
  const {
    location: { pathname },
  } = props

  return (
    <Layout pathName={pathname}>
      <SEO
        title="ERP"
        keywords={[`mivors`, `oracle cloud`, `realestate`]}
      />
      <Hero
        bg={HeroBackground}
        title="Work with the Oracle ERP Cloud Experts"
        description="Unify your business processes and enable the flow of data between them. Moving your ERP systems to the Cloud expedites and improves accuracy and access to that data. And if you have already made your move to the cloud, we can ensure that you are maximizing the return on your investment. Mivors has expertise in all areas of implementing and managing ERP."
      />
        <CustomerSegmentValueProps
          bgColor="var(--secondary-color)"
          features={ERP_FEATURES}
        />
        <CustomerSegmentCarousel
          img={ErpDescription}
          title="“Cloud-Ready Path <strong>(CRP) Methodology<strong>”"
          description="Leveraging our Cloud-Ready Path (CRP) Methodology, our certified consultants have deployed Oracle ERP Cloud at dozens of customers worldwide. With quality and consistency, using a proven Implementation methodology, we’ll become your trusted partner and knowledgeable advisor on your journey to the Cloud and help you manage it once you there."
        />
        <CustomerSegmentCenterTestingBlurb
          bgColor="var(--primary-color)"
          title="360° Oracle Cloud."
          description="An affordable and unparalleled solution for organisations looking for a fast, predictable and cost-effective route to the Cloud. Flexible and scalable, this modern finance Oracle Cloud solution allows you to do what you do best – manage your business, while Mivors implement, support and manage your system."
          images={[PC1, PC2, PC3]}
          content={
            <>
              <h3>Already Migrated to <strong>Oracle Cloud ERP?</strong></h3>
              <p>
                  Our Innovative Applications Support Model helps Oracle Cloud ERP Customers get more value out of the software they already own. Our team works with customers to build a plan to leverage the complete capabilities of the software and simplify their operations utilizing:   
                  <ul>
                    <li>
                      Customer Solution Team   
                    </li>
                    <li>
                      MUCS Platform
                    </li>
                    <li>
                      Customer Delivery Management Team
                    </li>
                  </ul>
                  mivors team, along with the MUCS platform gives you everything you need to operate your oracle cloud.
              </p>
            </>
          }
        />
        <CustomerSegmentApp
          title="ERP & Your Industry Opertaions integrated togother"
          description="We deliver Oracle ERP Cloud solution integrated with Icloud-ready operations excellense solutions across different industries which makes us the only vendor delivering end to end solutions and services for your unique industry needs"
          appStoreLink=""
          googlePlayLink=""
          direction="ltr"
          images={[Screen1, Screen2]}
          desktop={true}
        />
    </Layout>
  )
}

export default Makers
