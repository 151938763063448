import React from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper";
import mixins from "../lib/mixins";

const Wrapper = styled(MaxWidthWrapper)`
  margin-top: -80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  background-color: ${props => props.bgColor};
`;

const Feature = styled.div`
  ${mixins.flexColumnCenter};
  color: #fff;
  text-align: center;
  padding: 16px 0;

  img{
    margin-bottom: 8px;
  }

  p strong{
    display: block;
  }

  p{
    margin: 0 !important;
  }
`;

function Features({ features, bgColor }){
  return (
    <Wrapper bgColor={bgColor} maxWidth={900}>
      {
        features.map(({img, description}, index) => (
          <Feature>
            <img src={img} alt="feature description" />
            {description}
          </Feature>
        ))
      }
    </Wrapper>
  )
}

export default Features;
