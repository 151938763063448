import React from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper";

const Wrapper = styled(MaxWidthWrapper)`
  margin-top: 40px;  
`;

const Content = styled.div`
  background: ${props => `url(${props.bg})`} no-repeat center / cover;
  color: #fff;
  padding: 50px 50px 100px;

  .title{
    font-weight: 800;
    font-size: 1.7rem;
    margin: 0 0 20px;
    max-width: 600px;
  }

  .description{
    max-width: 500px;
    line-height: 1.6;
  }
`;

function ServiceHero({ bg, title, description }){
  return (
    <Wrapper as="section" maxWidth={1300}>
      <Content bg={bg}>
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
      </Content>
    </Wrapper>
  )
}

export default ServiceHero;
