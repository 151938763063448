import React from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper";
import mixins from "../lib/mixins";
import Polygon from "../images/polygon.svg";

const Section = styled.section`
  padding: 50px 0;
`;

const Wrapper = styled(MaxWidthWrapper)`
  ${mixins.flexColumnCenter};
  text-align: center;

  .title{
    ${mixins.flexCenter};
    font-weight: 700;
    font-size: 2.2rem;
    margin: 0 0 20px;
    max-width: ${props => `${props.titleMaxWidth}px`};
  }

  .title .polygon{
    width: 30px;
    vertical-align: middle;
    margin: 0 15px;
  }

  .content{
    font-size: 1.18rem;
  }

  .content p{
    margin: 0 0 20px;
    color: #727272;

    &:last-child{
      margin: 0;
    }
  }
`;

function CustomerSegmentQuestion1({ title, descriptions, titleMaxWidth }){
  return (
    <Section>
      <Wrapper maxWidth={1200} titleMaxWidth={titleMaxWidth}>
        <div className="title">
          <img className="polygon" src={Polygon} />
          <span>{title}</span>
          <img className="polygon" src={Polygon} />
        </div>

        <div className="content">
          {
            descriptions.map((p, index) => (
              <p key={index}>{p}</p>
            ))
          }
        </div>
      </Wrapper>
    </Section>
  )
}

export default CustomerSegmentQuestion1
