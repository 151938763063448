import React from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper.js";
import mixins from "../lib/mixins";
import ProductCarousel from "./ProductCarousel.js";
import Polygon from "../images/polygon.svg";

const Section = styled.section`
  background-color: ${props => props.bgColor};
  padding: 40px 0;
`;

const Wrapper = styled(MaxWidthWrapper)`
  ${mixins.flexColumnCenter};
  color: #fff;

  .title{
    font-weight: 400;
    font-size: 2.2rem;
    margin: 0 0 20px;
  }

  .description{
    font-size: 1.25rem;
    text-align: center;
    margin: 0 0 50px;
  }

  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    align-items: center;
  }

  .content h3{
    font-size: 2.2rem;
  }

  .content p{
    font-size: 1.15rem;
  }

  .content ul{
    padding: 0;
    margin: 10px 0;
    list-style-position: inside;
  }

  .images img{
    max-height: 350px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

function CustomerSegmentCenterTestingBlurb({
  bgColor,
  images,
  title,
  description,
  content
}){
  return (
      <Section bgColor={bgColor}>
      <Wrapper maxWidth={1200}>
        <h3 className="title"><span>{title}</span></h3>

        <p className="description">{description}</p>

        <div className="grid">
          <div className="content">{content}</div>

          <div className="images">
              <ProductCarousel
                autoPlay={true}
                interval={3000}
                infiniteLoop={true}
                arrows={true}
              >
                {images.map(img => {
                  return <img src={img} />
                })}
            </ProductCarousel>
          </div>
        </div>
      </Wrapper>
    </Section>
  )
}


export default CustomerSegmentCenterTestingBlurb
