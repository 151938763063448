import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import "array.prototype.move"
import MaxWidthWrapper from "./MaxWidthWrapper";

const Section = styled.section`
  margin: 20px 0 0;
  background-color: #fff;
  padding: 50px 0;
`;

const Wrapper = styled(MaxWidthWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-items: center;

  .content{
    max-width: 500px;
  }

  .title-wrapper{
    position: relative;

    .polygon{
      position: absolute;
      top: 20px;
      left: 20px;
      width 25px;
    }
  }

  .title{
    font-size: 2.5rem;
    margin: 0 0 20px;
  }

  .description{
    font-size: 1.2rem;
    color: #727272;
  }
`;

function CustomerSegmentCarousel({ img, title, description }){
  return (
    <Section>
      <Wrapper maxWidth={1200}>
        <div className="content">
          <div className="title-wrapper">
            <h3 className="title" dangerouslySetInnerHTML={{__html: title}}></h3>
          </div>
          
          <p className="description">
            {description}
          </p>
        </div>

        <div className="img-wrapper">
          <img src={img} />
        </div>
      </Wrapper>
    </Section>
  )
}

export default CustomerSegmentCarousel
