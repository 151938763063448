import React, { css } from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper";
import AppStorePicture from "../images/app-store.svg"
import GoogleStorePicture from "../images/google-play.svg"
import LargePolygon from "../images/large-polygon.png";
import mixins from "../lib/mixins";
import Laptop from "./Laptop";
import ProductCarousel from "./ProductCarousel";

const Section = styled.section`
  padding: 50px 0;
`;

const Wrapper = styled(MaxWidthWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-items: center;
`;

const Content = styled.div`
  .title{
    font-size: 2rem;
    font-weight: 400;
  }

  .description{
    font-size: 1.15rem;
    color: #727272;
  }

  .links a{
    margin: 0 15px 0 0;

    &:last-child{
      margin: 0;
    }
  }
`;

const Images = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  .phone{
    position: relative;
    z-index: 2;
    // ${mixins.mobileScreen};

    img{
      max-height: 100%;
      border-radius: 15px;
      border: 10px solid #474850;
      min-width: 180px;
      // object-fit: cover;
    }
  }

  .small-phone{
    height: 350px;
    transform: translate(30px, 30px);
    z-index: 3;
  }

  .large-phone{
    max-width: 200px;
    height: 400px;
  }

  .polygon{
    position: absolute;
    top: 0;
    left: 0;
    max-width: 300px;
  }
`;

const DesktopImages = styled.div`
  position: relative;

  .desktop{
    position: relative;
    z-index: 3;
  }

  .carousel{
    width: 100%;
    height: 100%;

    .carousel-root,
    .slider-wrapper,
    .slider-wrapper ul{
      height: 100%;
    }
  }

  .carousel img{
    width: 100%;
    // min-height: auto;
  }

  .polygon{
    position: absolute;
    top: 0;
    left: 0;
    max-width: 300px;
  }
`;

function CustomerSegmentApp({ title, description, images, desktop }){
  return (
    <Section>
      <Wrapper maxWidth={1200}>
        <Content>
          <h3 className="title">{title}</h3>
          <p className="description">{description}</p>
          {/* <div className="links">
            <a href="https://itunes.apple.com/us/app/mivors/?mt=8" target="_blank">
              <img src={AppStorePicture} />
            </a>

            <a href="https://play.google.com/store/apps/details?id=me.mivors.tc2" target="_blank">
              <img src={GoogleStorePicture} />
            </a>
            </div> */}
          </Content>

          {
            desktop ?
              <DesktopImages>
                <Laptop className="desktop">
                  <div className="carousel">
                    <ProductCarousel
                      autoPlay={true}
                      interval={3000}
                      infiniteLoop={true}
                      arrows={true}
                    >
                      {images.map(img => {
                        return <img className="laptop-img" src={img} />
                      })}
                    </ProductCarousel>
                  </div>
                </Laptop>
                <img src={LargePolygon} className="polygon" /> 
              </DesktopImages>
              :
              <Images>
                <div className="phone small-phone">
                  <img src={images[0]} className="screen" />
                </div>
    
                <div className="phone large-phone">
                  <img src={images[1]} className="screen" />
                </div>
    
                <img src={LargePolygon} className="polygon" /> 
              </Images>  
          }
      </Wrapper>
    </Section>
  )
}

export default CustomerSegmentApp
